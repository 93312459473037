import styled from 'styled-components';
import { getBreakpoint } from '../../../base/style';
import Button from '../../../components/Button';
import { InstalmentPaymentType } from '../../../redux/payment-plan-instalment/payment-plan-instalment.types';

export const PaymentOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: ${(props) => props.theme.colors.bgHoverAccent};
  border-radius: 12px;
  padding: 24px;
  text-align: center;

  h3,
  button {
    margin: 10px;
    @media screen and (min-width: ${getBreakpoint('lg')}) {
      margin: 0 10px;
    }
  }

  h3 {
    @media screen and (min-width: ${getBreakpoint('lg')}) {
      width: 350px;
    }
  }

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    /* align-items: center; */
    display: flex;
    /* justify-content: center; */
    text-align: left;
    flex-direction: row;
  }
`;

export const PaymentOptionButton = styled(Button)<{ selected: boolean; paymentType: InstalmentPaymentType }>`
  width: 185px;
  justify-content: center;
  background: ${(props) =>
    props.selected
      ? props.paymentType === InstalmentPaymentType.REMAINING_BALANCE
        ? props.theme.colors.positive
        : props.theme.colors.warning
      : '#fff'};
  color: ${(props) => (props.selected ? '#fff' : '#000')};

  &:hover {
    background: ${(props) =>
      props.selected
        ? props.paymentType === InstalmentPaymentType.REMAINING_BALANCE
          ? props.theme.colors.positive
          : props.theme.colors.warning
        : 'inherit'};
  }

  &:disabled {
    background: ${(props) =>
      props.selected
        ? props.paymentType === InstalmentPaymentType.REMAINING_BALANCE
          ? props.theme.colors.positive
          : props.theme.colors.warning
        : '#fff'};
    color: ${(props) => (props.selected ? '#fff' : '#000')};
    opacity: 0.5;

    &:hover {
      background: ${(props) =>
        props.selected
          ? props.paymentType === InstalmentPaymentType.REMAINING_BALANCE
            ? props.theme.colors.positive
            : props.theme.colors.warning
          : '#fff'};
      color: ${(props) => (props.selected ? '#fff' : '#000')};
      opacity: 0.5;
    }
  }
`;
