import { Stripe, StripeElements } from '@stripe/stripe-js';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REPOSIT_PAYMENT_WARNING } from '../../constants/reposit';
import { getCurrentCustomerId } from '../../redux/account/account.selectors';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import {
  getClaimPaymentIntentRequested,
  GET_CLAIM_PAYMENT_INTENT_STORE_KEY,
  payRequested,
  PAY_STORE_KEY,
} from '../../redux/order-customer-actions/order-customer-actions.actions';
import {
  getClaimPaymentIntentSecret,
  getIsPollingForPaymentCompletion,
} from '../../redux/order-customer-actions/order-customer-actions.selectors';
import { PaymentType } from '../../redux/order-customer-actions/order-customer-actions.types';
import { fetchOrderCustomerRequested, FETCH_ORDER_CUSTOMER_STORE_KEY } from '../../redux/order/order.actions';
import { getCurrentOrderCustomer } from '../../redux/order/order.selectors';
import PaymentContainer from '../Payment';
import Loading from '../../components/Loading';

interface ArbitrationPaymentProps {
  orderId: string;
}

const WarningMessage = () => {
  return (
    <>
      <div>
        You must complete payment for this dispute to be considered. If payment is not made, these end of tenancy charges will be
        considered accepted and you will take full liability of the charges.
      </div>
      <div style={{ marginTop: '1em' }}>{REPOSIT_PAYMENT_WARNING}</div>
    </>
  );
};

const ArbitrationPayment: React.FC<ArbitrationPaymentProps> = ({ orderId }) => {
  const dispatch = useDispatch();
  const currentCustomerId = useSelector(getCurrentCustomerId);
  const paymentIntentSecret = useSelector(getClaimPaymentIntentSecret);
  const getPayRepositLoadingSelector = createLoadingSelector([PAY_STORE_KEY]);
  const isPaymentLoading = useSelector(getPayRepositLoadingSelector);
  const currentOrderCustomer = useSelector(getCurrentOrderCustomer);
  const amount = get(currentOrderCustomer, 'fee');
  const pageLoadingSelector = createLoadingSelector([FETCH_ORDER_CUSTOMER_STORE_KEY, GET_CLAIM_PAYMENT_INTENT_STORE_KEY]);
  const isPageLoading = useSelector(pageLoadingSelector);
  const isPolling = useSelector(getIsPollingForPaymentCompletion);

  useEffect(() => {
    if (orderId && currentCustomerId) {
      dispatch(getClaimPaymentIntentRequested(currentCustomerId, orderId));
      dispatch(fetchOrderCustomerRequested({ customerId: currentCustomerId, orderId }));
    }
  }, [dispatch, currentCustomerId, orderId]);

  const submitPayment = (stripe: Stripe, elements: StripeElements) =>
    dispatch(payRequested({ stripe, paymentIntentSecret, type: PaymentType.ARBITRATION_FEE, elements }));
  const isLoading = isPageLoading || isPolling || isPaymentLoading;

  const render = () => {
    return amount ? (
      <PaymentContainer
        fullWidth
        isSubmitting={isLoading}
        amount={`${amount}`}
        submitCard={submitPayment}
        type={PaymentType.ARBITRATION_FEE}
        warningMessage={WarningMessage}
        title="Formal dispute admin fee"
        paymentMode={'payment'}
      />
    ) : (
      <Loading />
    );
  };

  return render();
};

export default ArbitrationPayment;
