import {
  IsDuplicateResponse,
  OrderCustomerActionDTO,
  OrderCustomerActionsApi,
  OrderCustomerDTO,
  OrderCustomersApi,
  OrdersApi,
} from '@reposit/api-client';
import { PaymentIntent, PaymentMethod, StripeError } from '@stripe/stripe-js';
import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { FlashState } from '../../components/FlashMessage/index';
import { getErrorMessage } from '../../utils/common.utils';
import { sendEventToGoogleAnalytics } from '../../utils/integrators/analytics.integrator';
import { AddresHistoryActionTypes, setIsAddNewFormOpen } from '../address-history/address-history.actions';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import { fetchOrderCustomerRequested, OrderActionTypes } from '../order/order.actions';
import { getCurrentOrderCustomer } from '../order/order.selectors';
import SCHEMA from '../schema';
import { getCurrentUser } from '../selectors/user.selectors';
import { createOrderCustomerActionApi, createOrderCustomersApi, createOrdersApi, runSagaWithAuth } from '../utils/api.utils';
import {
  confirmRepositFailed,
  confirmRepositSuccess,
  CONFIRM_REPOSIT_API_REQUESTED,
  CONFIRM_REPOSIT_STORE_KEY,
  getClaimPaymentIntentFailed,
  getClaimPaymentIntentSuccess,
  getPaymentIntentFailed,
  getPaymentIntentSuccess,
  GET_CLAIM_PAYMENT_INTENT_API_REQUESTED,
  GET_CLAIM_PAYMENT_INTENT_STORE_KEY,
  GET_PAYMENT_INTENT_API_REQUESTED,
  GET_PAYMENT_INTENT_STORE_KEY,
  OrderCustomerActionsActionTypes,
  payFailed,
  paySuccess,
  PAY_API_REQUESTED,
  PAY_STORE_KEY,
  pollPaymentSuccessFailed,
  pollPaymentSuccessRequested,
  pollPaymentSuccessSuccess,
  POLL_PAYMENT_SUCCESS_API_CANCELLED,
  POLL_PAYMENT_SUCCESS_API_FAILED,
  POLL_PAYMENT_SUCCESS_API_REQUESTED,
  POLL_PAYMENT_SUCCESS_API_SUCCESS,
  signAddendumFailed,
  signAddendumSuccess,
  signRepositFailed,
  signRepositSuccess,
  SIGN_ADDENDUM_API_REQUESTED,
  SIGN_ADDENDUM_STORE_KEY,
  SIGN_REPOSIT_API_REQUESTED,
  SIGN_REPOSIT_STORE_KEY,
  updateAboutYouFailed,
  updateAboutYouSuccess,
  updateAddressHistoryFailed,
  updateAddressHistorySuccess,
  UPDATE_ABOUT_YOU_API_REQUESTED,
  UPDATE_ABOUT_YOU_STORE_KEY,
  UPDATE_ADDRESS_HISTORY_API_REQUESTED,
  UPDATE_ADDRESS_HISTORY_STORE_KEY,
} from './order-customer-actions.actions';
import {
  OrderActionRepositPayload,
  PaymentType,
  PayPayload,
  PollPaymentSuccessPayload,
  SignAddendumPayload,
} from './order-customer-actions.types';

// ****************
// WORKERS
// ****************
function delay(duration: number) {
  const promise = new Promise((resolve) => {
    setTimeout(() => resolve(true), duration);
  });
  return promise;
}

export function* updateAboutYou({ payload }: { type: string; payload: OrderActionRepositPayload }) {
  try {
    const { orderId, customerId, data } = payload;
    const orderCustomerActionsApi: OrderCustomerActionsApi = yield createOrderCustomerActionApi();
    const apiResponse = yield call(runSagaWithAuth(() => orderCustomerActionsApi.aboutYou(orderId, customerId, data)));

    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.user);
    yield put<OrderCustomerActionsActionTypes>(updateAboutYouSuccess());
    yield put(push(`/checkout/${orderId}/${customerId}/`));
    yield put<OrderActionTypes>(fetchOrderCustomerRequested({ customerId, orderId }));
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<OrderCustomerActionsActionTypes>(updateAboutYouFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_ABOUT_YOU_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* updateAddressHistory({ payload }: { type: string; payload: OrderActionRepositPayload }) {
  try {
    const { orderId, customerId } = payload;
    const orderCustomerActionsApi: OrderCustomerActionsApi = yield createOrderCustomerActionApi();
    const currentUser = yield select(getCurrentUser);
    const addresses = currentUser.attributes.addresses || [];

    yield call(
      runSagaWithAuth(() =>
        orderCustomerActionsApi.addressHistory(orderId, customerId, {
          addresses,
        })
      )
    );

    yield put<OrderCustomerActionsActionTypes>(updateAddressHistorySuccess());
    yield put<AddresHistoryActionTypes>(setIsAddNewFormOpen(false));
    yield put<OrderActionTypes>(fetchOrderCustomerRequested({ customerId, orderId }));
  } catch (e) {
    const error = getErrorMessage(e);

    yield put<OrderCustomerActionsActionTypes>(updateAddressHistoryFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_ADDRESS_HISTORY_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* confirmReposit({ payload }: { type: string; payload: OrderActionRepositPayload }) {
  try {
    const { customerId, orderId } = payload;
    const orderCustomerActionsApi: OrderCustomerActionsApi = yield createOrderCustomerActionApi();
    yield call(runSagaWithAuth(() => orderCustomerActionsApi.confirm(orderId, customerId)));
    yield put<OrderCustomerActionsActionTypes>(confirmRepositSuccess());
    sendEventToGoogleAnalytics({
      action: 'Confirmed',
      category: 'Tenant',
      label: '',
    });
    yield put(push(`/checkout/${orderId}/${customerId}/`));
    yield put<OrderActionTypes>(fetchOrderCustomerRequested({ customerId, orderId }));
  } catch (e) {
    const error = get(e, 'response.data.message', e);
    yield put<OrderCustomerActionsActionTypes>(confirmRepositFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CONFIRM_REPOSIT_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* signReposit({ payload }: { type: string; payload: OrderActionRepositPayload }) {
  try {
    const { customerId, orderId } = payload;
    const orderCustomerActionsApi: OrderCustomerActionsApi = yield createOrderCustomerActionApi();
    yield call(runSagaWithAuth(() => orderCustomerActionsApi.sign(orderId, customerId)));
    yield put<OrderCustomerActionsActionTypes>(signRepositSuccess());
    yield put(push(`/checkout/${orderId}/${customerId}/`));
    yield put<OrderActionTypes>(fetchOrderCustomerRequested({ customerId, orderId }));
  } catch (e) {
    const error = get(e, 'response.data.message', e);
    yield put<OrderCustomerActionsActionTypes>(signRepositFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: SIGN_REPOSIT_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* signAddendum({ payload }: { type: string; payload: SignAddendumPayload }) {
  const { orderId, customerId } = payload;
  try {
    const api: OrderCustomerActionsApi = yield createOrderCustomerActionApi();
    yield call(runSagaWithAuth(() => api.signAddendumForTenant(orderId, customerId)));
    yield put<OrderCustomerActionsActionTypes>(signAddendumSuccess());
    yield put(push(`/checkout/${orderId}/${customerId}/`));
    yield put<OrderActionTypes>(fetchOrderCustomerRequested({ customerId, orderId }));
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<OrderCustomerActionsActionTypes>(signAddendumFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: SIGN_ADDENDUM_STORE_KEY, message: error, state: FlashState.ERROR })
    );
  }
}

export function* getPaymentIntent({ payload }: { type: string; payload: OrderActionRepositPayload }) {
  try {
    const { customerId, orderId } = payload;
    const orderCustomerActionsApi: OrderCustomerActionsApi = yield createOrderCustomerActionApi();
    const { data } = yield call(runSagaWithAuth(() => orderCustomerActionsApi.pay(orderId, customerId)));
    yield put<OrderCustomerActionsActionTypes>(getPaymentIntentSuccess(data.paymentIntentSecret));
  } catch (e) {
    const error = get(e, 'response.data.message', e);
    yield put<OrderCustomerActionsActionTypes>(getPaymentIntentFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: GET_PAYMENT_INTENT_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* getClaimPaymentIntent({ payload }: { type: string; payload: OrderActionRepositPayload }) {
  try {
    const { customerId, orderId, paymentType } = payload;
    const orderCustomerActionsApi: OrderCustomerActionsApi = yield createOrderCustomerActionApi();
    const { data } = yield call(runSagaWithAuth(() => orderCustomerActionsApi.pay(orderId, customerId, paymentType)));
    yield put<OrderCustomerActionsActionTypes>(getClaimPaymentIntentSuccess(data.paymentIntentSecret));
  } catch (e) {
    const error = get(e, 'response.data.message', e);
    yield put<OrderCustomerActionsActionTypes>(getClaimPaymentIntentFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: GET_CLAIM_PAYMENT_INTENT_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* pay({ payload }: { type: string; payload: PayPayload }) {
  try {
    const { paymentIntentSecret, stripe, type, elements } = payload;
    const { order, customer } = yield select(getCurrentOrderCustomer);

    const getURL = (type: PaymentType) => {
      switch (type) {
        case PaymentType.REPOSIT:
          return `/checkout/${order.id}/${customer.id}/finish`;
        case PaymentType.CLAIM:
          return '/claims/payment-success';
        case PaymentType.CLAIM_REMAINING_BALANCE:
          return '/claims/payment-success-remaining-balance';
        case PaymentType.ARBITRATION_FEE:
          return '/claims/arbitration-payment-success';
        case PaymentType.ARBITRATION:
          return '/claims/payment-success';
        case PaymentType.TOP_UP:
          return '/annual-fee/payment-success';
        case PaymentType.CLAIM_PROPOSAL:
          return '/claims/proposal-payment-success';
      }
    };

    const {
      paymentMethod,
      error,
    }: {
      paymentMethod?: PaymentMethod | undefined;
      error?: StripeError | undefined;
    } = yield stripe.createPaymentMethod({
      elements: elements as any,
    });

    if (!paymentMethod || error) {
      yield put<OrderCustomerActionsActionTypes>(payFailed(error && error.message ? error.message : 'An error has occured'));
      yield put<FlashMessagesActionTypes>(
        setFlashMessage({
          key: PAY_STORE_KEY,
          message: error && error.message ? error.message : 'An error has occured',
          state: FlashState.ERROR,
        })
      );
      return;
    }

    if (type === PaymentType.REPOSIT) {
      const ordersApi: OrdersApi = yield createOrdersApi();
      const { data }: { data: IsDuplicateResponse } = yield call(
        runSagaWithAuth(() => ordersApi.duplicatePaymentMethodCheck(order.id, { paymentMethodId: paymentMethod.id }))
      );

      if (data.isDuplicate) {
        yield put<OrderCustomerActionsActionTypes>(payFailed('Duplicate card'));
        yield put<FlashMessagesActionTypes>(
          setFlashMessage({
            key: PAY_STORE_KEY,
            message:
              'The card you have used is shared with another tenant. To pay for Reposit you must use your own card that has not already been used.',
            state: FlashState.ERROR,
          })
        );
        return;
      }
    }

    const {
      paymentIntent,
      error: confirmError,
    }: {
      paymentIntent?: PaymentIntent | undefined;
      error?: StripeError | undefined;
    } = yield stripe.confirmCardPayment(paymentIntentSecret, { payment_method: paymentMethod.id });

    if (!paymentIntent || confirmError) {
      let paymentErrorMessage;
      if (confirmError && confirmError.decline_code === 'generic_decline') {
        if (
          confirmError &&
          confirmError.payment_method &&
          confirmError.payment_method.card &&
          confirmError.payment_method?.card.funding === 'prepaid'
        ) {
          paymentErrorMessage = 'We are unable to accept prepaid cards. Please try a different payment method.';
        } else {
          paymentErrorMessage = 'Your card was declined. Please check your CVC, billing address, and postal code are correct.';
        }
      } else {
        paymentErrorMessage = confirmError && confirmError.message ? confirmError.message : 'An error has occured';
      }
      yield put<OrderCustomerActionsActionTypes>(payFailed(paymentErrorMessage));
      yield put<FlashMessagesActionTypes>(
        setFlashMessage({
          key: PAY_STORE_KEY,
          message: paymentErrorMessage,
          state: FlashState.ERROR,
        })
      );
      return;
    }

    if (order.redirectUrl) {
      window.location = order.redirectUrl;
      return;
    }

    yield put<OrderCustomerActionsActionTypes>(paySuccess());
    yield put<OrderCustomerActionsActionTypes>(
      pollPaymentSuccessRequested({ redirectUrl: getURL(type) as string, paymentIntentId: paymentIntent.id })
    );
  } catch (e) {
    yield put<OrderCustomerActionsActionTypes>(payFailed(e));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: PAY_STORE_KEY,
        message: e.message,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* pollPaymentSuccess({ payload }: { type: string; payload: PollPaymentSuccessPayload }) {
  try {
    const { paymentIntentId, redirectUrl } = payload;
    const { customer, order } = yield select(getCurrentOrderCustomer);
    const orderCustomersApi: OrderCustomersApi = yield createOrderCustomersApi();
    let running = true;
    while (running) {
      const apiResponse: AxiosResponse<OrderCustomerDTO> = yield call(
        runSagaWithAuth(() => orderCustomersApi.getOrderCustomerById(order.id, customer.id))
      );
      const { data } = apiResponse;
      const paymentAction = (data.actions as OrderCustomerActionDTO[]).find((action: any) => {
        const details = action.details || {};
        const actionPaymentIntentId = details.stripePaymentIntentId;
        return actionPaymentIntentId === paymentIntentId;
      });

      if (!paymentAction || !paymentAction.completedAt) {
        yield call(delay, 2000);
      } else {
        running = false;
        yield put(push(redirectUrl));
        yield put<OrderCustomerActionsActionTypes>(pollPaymentSuccessSuccess());
      }
    }
  } catch (e) {
    yield put<OrderCustomerActionsActionTypes>(pollPaymentSuccessFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchOrderCustomerActionSagas() {
  yield takeLatest(UPDATE_ABOUT_YOU_API_REQUESTED, updateAboutYou);
  yield takeLatest(UPDATE_ADDRESS_HISTORY_API_REQUESTED, updateAddressHistory);
  yield takeLatest(CONFIRM_REPOSIT_API_REQUESTED, confirmReposit);
  yield takeLatest(SIGN_REPOSIT_API_REQUESTED, signReposit);
  yield takeLatest(SIGN_ADDENDUM_API_REQUESTED, signAddendum);
  yield takeLatest(GET_PAYMENT_INTENT_API_REQUESTED, getPaymentIntent);
  yield takeLatest(GET_CLAIM_PAYMENT_INTENT_API_REQUESTED, getClaimPaymentIntent);
  yield takeLatest(PAY_API_REQUESTED, pay);

  while (true) {
    const action = yield take(POLL_PAYMENT_SUCCESS_API_REQUESTED);
    yield race({
      poll: call(pollPaymentSuccess, action),
      success: take(POLL_PAYMENT_SUCCESS_API_SUCCESS),
      cancelled: take(POLL_PAYMENT_SUCCESS_API_CANCELLED),
      failed: take(POLL_PAYMENT_SUCCESS_API_FAILED),
    });
  }
}
