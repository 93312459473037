import { OrderCustomerActionDTOTypeIdEnum } from '@reposit/api-client';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { Header3 } from '../../components/Typography/index';
import { getCurrentCustomerId } from '../../redux/account/account.selectors';
import { getClaimRespondentsByClaimId } from '../../redux/entities/entities.selectors';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import {
  getClaimPaymentIntentRequested,
  GET_CLAIM_PAYMENT_INTENT_STORE_KEY,
  payRequested,
  PAY_STORE_KEY,
} from '../../redux/order-customer-actions/order-customer-actions.actions';
import {
  getClaimPaymentIntentSecret,
  getIsPollingForPaymentCompletion,
  getOrderCustomerActionsByIds,
} from '../../redux/order-customer-actions/order-customer-actions.selectors';
import { PaymentType } from '../../redux/order-customer-actions/order-customer-actions.types';
import { fetchOrderCustomerRequested, FETCH_ORDER_CUSTOMER_STORE_KEY } from '../../redux/order/order.actions';
import { getCurrentOrderCustomer } from '../../redux/order/order.selectors';
import PaymentContainer, { PaymentAmountType } from '../Payment/index';
import { PaymentOptionButton, PaymentOptionWrapper } from './components';
import Loading from '../../components/Loading';

interface ClaimPaymentProps {
  orderId: string;
  claimId: string;
}

const ClaimPayment: React.FC<ClaimPaymentProps> = ({ orderId, claimId }) => {
  const dispatch = useDispatch();
  const [ocaPaymentType, setOcaPaymentType] = useState<OrderCustomerActionDTOTypeIdEnum | undefined>();

  const currentCustomerId = useSelector(getCurrentCustomerId);
  const paymentIntentSecret = useSelector(getClaimPaymentIntentSecret);
  const getPayRepositLoadingSelector = createLoadingSelector([PAY_STORE_KEY]);
  const isPayRepositLoading = useSelector(getPayRepositLoadingSelector);
  const currentOrderCustomer = useSelector(getCurrentOrderCustomer);
  const currentOrderCustomerFee = get(currentOrderCustomer, 'fee');
  const claimRespondents = useSelector(getClaimRespondentsByClaimId(claimId));
  const onlyOneClaimRespondent = claimRespondents && Object.values(claimRespondents).length === 1;
  const nextActionIds = get(currentOrderCustomer, 'nextAction.orderCustomerActionIds', []);

  const nextActions = useSelector(getOrderCustomerActionsByIds(nextActionIds));
  const remainingBalanceAction = nextActions.find((a) => a.typeId === OrderCustomerActionDTOTypeIdEnum.CLAIMPAYREMAININGBALANCE);

  const amountRemaining = get(remainingBalanceAction, 'details.amountRemaining');
  const amount = ocaPaymentType === OrderCustomerActionDTOTypeIdEnum.CLAIMPAY ? currentOrderCustomerFee : amountRemaining;
  const completeNextAction = nextActions.find((a) => a.completedAt);

  const isRemaningBalanceNotEqualToOrderCustomerFee = currentOrderCustomerFee !== amountRemaining;
  const paymentAmountType =
    ocaPaymentType === OrderCustomerActionDTOTypeIdEnum.CLAIMPAYREMAININGBALANCE || onlyOneClaimRespondent
      ? PaymentAmountType.REMAINING_BALANCE
      : PaymentAmountType.SHARE;
  const paymentTitle =
    paymentAmountType === PaymentAmountType.REMAINING_BALANCE
      ? 'The remaining balance for these charges is'
      : 'Your share of these charges is';

  const paymentType: PaymentType =
    paymentAmountType === PaymentAmountType.REMAINING_BALANCE ? PaymentType.CLAIM_REMAINING_BALANCE : PaymentType.CLAIM;

  useEffect(() => {
    // check everything is loaded
    // AND
    // paymentType has not been set
    if (nextActionIds.length && currentOrderCustomerFee && !ocaPaymentType) {
      const initialType = remainingBalanceAction
        ? OrderCustomerActionDTOTypeIdEnum.CLAIMPAYREMAININGBALANCE
        : OrderCustomerActionDTOTypeIdEnum.CLAIMPAY;
      setOcaPaymentType(initialType);
    }
  }, [
    nextActionIds,
    remainingBalanceAction,
    completeNextAction,
    ocaPaymentType,
    currentOrderCustomerFee,
    amountRemaining,
    isRemaningBalanceNotEqualToOrderCustomerFee,
  ]);

  const pageLoadingSelector = createLoadingSelector([FETCH_ORDER_CUSTOMER_STORE_KEY, GET_CLAIM_PAYMENT_INTENT_STORE_KEY]);
  const isPageLoading = useSelector(pageLoadingSelector);
  const isPolling = useSelector(getIsPollingForPaymentCompletion);

  const submitPayment = (stripe: Stripe, elements: StripeElements) =>
    dispatch(payRequested({ stripe, paymentIntentSecret, type: paymentType, elements }));

  useEffect(() => {
    dispatch(fetchOrderCustomerRequested({ customerId: currentCustomerId, orderId }));
  }, [dispatch, orderId, currentCustomerId]);

  useEffect(() => {
    if (ocaPaymentType) {
      dispatch(getClaimPaymentIntentRequested(currentCustomerId, orderId, ocaPaymentType));
    }
  }, [dispatch, orderId, currentCustomerId, ocaPaymentType]);

  const actionsGreaterThanOne = nextActionIds.length > 1;

  // more than one action
  // AND
  // NO complete actions
  // AND
  // remaining balance is not equal to OC fee
  const shouldShowPaymentOptions = actionsGreaterThanOne && !completeNextAction && isRemaningBalanceNotEqualToOrderCustomerFee;
  const isLoading = isPageLoading || isPolling || isPayRepositLoading;

  return (
    <>
      {shouldShowPaymentOptions ? (
        <Container>
          <Row>
            <Col sm={12}>
              <div style={{ paddingTop: '1.75rem' }}>
                <PaymentOptionWrapper>
                  <Header3>How much would you like to pay?</Header3>
                  <div>
                    <PaymentOptionButton
                      paymentType={OrderCustomerActionDTOTypeIdEnum.CLAIMPAYREMAININGBALANCE}
                      selected={ocaPaymentType === OrderCustomerActionDTOTypeIdEnum.CLAIMPAYREMAININGBALANCE}
                      buttonType="tertiary"
                      onClick={() => setOcaPaymentType(OrderCustomerActionDTOTypeIdEnum.CLAIMPAYREMAININGBALANCE)}
                    >
                      Remaining Balance
                    </PaymentOptionButton>
                    <PaymentOptionButton
                      paymentType={OrderCustomerActionDTOTypeIdEnum.CLAIMPAY}
                      selected={ocaPaymentType === OrderCustomerActionDTOTypeIdEnum.CLAIMPAY}
                      buttonType="tertiary"
                      onClick={() => setOcaPaymentType(OrderCustomerActionDTOTypeIdEnum.CLAIMPAY)}
                    >
                      Your Share
                    </PaymentOptionButton>
                  </div>
                </PaymentOptionWrapper>
              </div>
            </Col>
          </Row>
        </Container>
      ) : null}
      {amount ? (
        <PaymentContainer
          fullWidth
          isSubmitting={isLoading}
          amount={`${amount}`}
          submitCard={submitPayment}
          type={paymentType}
          title={paymentTitle}
          paymentAmountType={paymentAmountType}
          paymentMode={'payment'}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ClaimPayment;
