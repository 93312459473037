import { OrderCustomerActionDTOTypeIdEnum } from '@reposit/api-client';
import { Stripe, StripeElements } from '@stripe/stripe-js';

export interface OrderCustomerActionsState {
  paymentIntentSecret: string;
  claimPaymentIntentSecret: string;
  isPolling: boolean;
}

export interface OrderActionRepositPayload {
  orderId: string;
  customerId: string;
  paymentType?: OrderCustomerActionDTOTypeIdEnum;
  data?: any;
}

export enum PaymentType {
  REPOSIT = 'REPOSIT',
  CLAIM = 'CLAIM',
  CLAIM_REMAINING_BALANCE = 'CLAIM_REMAINING_BALANCE',
  ARBITRATION_FEE = 'ARBITRATION_FEE',
  ARBITRATION = 'ARBITRATION',
  TOP_UP = 'TOP_UP',
  CLAIM_PROPOSAL = 'CLAIM_PROPOSAL',
}

export interface PayPayload {
  stripe: Stripe;
  elements: StripeElements;
  paymentIntentSecret: string;
  type: PaymentType;
}

export interface PollPaymentSuccessPayload {
  redirectUrl: string;
  paymentIntentId: string;
}

export interface SignAddendumPayload {
  orderId: string;
  customerId: string;
}
