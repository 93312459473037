import { createAsyncThunk } from '@reduxjs/toolkit';
import { AddressDTO, PaymentPlanDTO, PaymentPlansApi } from '@reposit/api-client/dist';
import { AxiosResponse } from 'axios';
import { standardSyncEntitiesAndGetResults } from '../entities/entities.sagas';
import { AddressEntity, PaymentPlanEntity } from '../entities/entities.types';
import { AppState } from '../root.reducer';
import SCHEMA from '../schema';
import { createStandardPaymentPlansApi, runThunkWithAuth } from '../utils/api.utils';
import { FetchPaymentPlanPayload, FetchPaymentPlanPropertyAddressPayload } from './payment-plan.types';

export const fetchPaymentPlanThunk = createAsyncThunk<PaymentPlanEntity, FetchPaymentPlanPayload, { state: AppState }>(
  `payment-plan/fetch`,
  async (payload, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    try {
      const api: PaymentPlansApi = createStandardPaymentPlansApi(state);
      const apiResponse: AxiosResponse<PaymentPlanDTO> = await runThunkWithAuth(
        () => api.getPaymentPlanById(payload.id),
        dispatch
      );
      const entity: PaymentPlanEntity = standardSyncEntitiesAndGetResults(apiResponse.data, SCHEMA.paymentPlan, dispatch);
      return entity;
    } catch (e) {
      throw e;
    }
  }
);

export const fetchPaymentPlanPropertyAddressThunk = createAsyncThunk<
  AddressEntity,
  FetchPaymentPlanPropertyAddressPayload,
  { state: AppState }
>(`payment-plan/fetch-property-address`, async (payload, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  const state = thunkAPI.getState();
  try {
    const api: PaymentPlansApi = createStandardPaymentPlansApi(state);
    const { data }: AxiosResponse<AddressDTO> = await runThunkWithAuth(
      () => api.getPaymentPlanPropertyAddressById(payload.paymentPlanId),
      dispatch
    );
    return data;
  } catch (e) {
    throw e;
  }
});
